.container {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 1em;
  border-radius: .6em;
  border: 3px solid #f4d7ff;
  transition: 0.5s all;
}

.container:focus-within {
  border: 3px solid #000;
}

.input {
  border: 0;
  width: 70%;
  font-size: 1.1rem;
  text-transform: uppercase;
}

.input:focus {
  outline: none;
  border: 0;
}

.button {
  color: #fff;
  background: #300030;
  border: 0;
  padding: .6em 1.1em;
  border-radius: .6em;
  font-size: .9rem;
  font-weight: 300;
  cursor: pointer;
}
