.hero {
  background: #d7f0ff;
  border-radius: 12px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 40vh;
  margin-bottom: 2em;
  padding: 64px 100px;
}

.title {
  font-weight: 700;
  font-size: 3em;
  margin-bottom: 0.2em;
  padding: 0;
}

.image {
  margin-top: 2em;
}
