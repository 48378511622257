.header {
  display: flex;
  padding: 1em 100px;
  align-items: center;
  justify-content: space-between;
}

.link {
  display: inline-block;
  margin-left: 1em;
}

  .link:hover {
    color: #601748;
  }

  .active {
    color: red;
  }

  .padding {
    padding-bottom: 2em;
  }
