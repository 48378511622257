.container {
  width: calc(100% - 200pt);
  margin: 0 auto;
}

@media (max-width: 600px) {
  .container {
    width: 100%;
  }
}
