.hero {
  background-color: #f4d7ff;
  min-height: 40vh;
  padding: 64px 100px;
  margin-bottom: 2em;
}

.content {
  max-width: 40vw;
}

.title {
  font-size: 2.2rem;
  font-weight: 500;
}

p {
  font-size: 1.1rem;
  margin-bottom: 1em;
}

.helper {
  font-size: .7em;
  padding-top: 5px;
  padding-left: 5px;
}
