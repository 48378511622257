.banner {
  background: #d5e5ee;
  padding: 100px;
}

.reg {
  text-align: center;
  border-radius: 12px;
  letter-spacing: 6px;
  font-size: 3em;
  background: #fff;
  border: 6px solid #000;
}
